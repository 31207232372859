import React, { useLayoutEffect, useRef } from 'react'
import DITWrapper from '@/components/Organisms/DIT/DITConstructor/DITWrapper'
import { useActions, useAppSelector } from '@/hooks'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
import {
  extractLayoutItems,
  extractLayoutStyles,
  combineItems,
  combineLayout,
} from './helper'
import DITButtons from './DITButtons'
import {
  ConstructorItemProps,
  ConstructorLayoutItem,
  ConstructorStyleItem,
} from '@/components/Organisms/DIT/types'
import DITImages from '@/components/Organisms/DIT/DITImages/DITImages'
import SaveButtons from '@/components/Organisms/DIT/SaveButtons/SaveButtons'

type Props = {
  width: number
  height: number
}
const DIT: React.FC<Props> = ({ width, height }) => {
  const { ditSetSlideItem } = useActions()
  const programs = useAppSelector(ditSliceSelectors.stateDitPrograms)
  const activeProgram = useAppSelector(ditSliceSelectors.stateDitActiveProgram)
  const activeSlide = useAppSelector(ditSliceSelectors.stateDitActiveSlide)
  const activeItem = useAppSelector(ditSliceSelectors.stateDitActiveSlideItem)
  const isDisabled = useAppSelector(
    ditSliceSelectors.stateDitActiveProgramDisabled
  )
  if (!activeProgram || !activeSlide || !activeItem) return null
  // @ts-ignore
  const curItems = programs
    .find((program) => program.id === activeProgram)
    .slides.find((slide) => slide.id === activeSlide).items
  const constructorLayout = extractLayoutItems(
    curItems,
    activeItem,
    width,
    height,
    isDisabled
  )
  const constructorStyles = extractLayoutStyles(curItems)
  const activeItemProps = constructorStyles.find(
    (item) => item.id === activeItem
  )
  const handleConstructorLayout = (items: ConstructorLayoutItem[]) => {
    const res = combineLayout(items, curItems)
    ditSetSlideItem({
      items: res,
      programId: activeProgram,
      slideId: activeSlide,
    })
  }
  const handleConstructorProps = (item: ConstructorItemProps) => {
    const res = combineItems(item, curItems)
    ditSetSlideItem({
      items: res,
      programId: activeProgram,
      slideId: activeSlide,
    })
  }
  return (
    <>
      {!isDisabled && (
        <>
          {activeItemProps?.type === 'text' && (
            <DITButtons
              activeSlide={activeSlide}
              activeItemProps={activeItemProps}
              activeItem={activeItem}
              handleConstructorProps={handleConstructorProps}
            />
          )}
          {activeItemProps?.type === 'image' && (
            <DITImages
              width={width}
              height={height}
              activeItemProps={activeItemProps}
              activeItem={activeItem}
              handleConstructorProps={handleConstructorProps}
            />
          )}
        </>
      )}
      <DITWrapper
        width={width}
        height={height}
        constructorStyles={constructorStyles}
        constructorLayout={constructorLayout}
        handleConstructorLayout={handleConstructorLayout}
      />
      <SaveButtons />
    </>
  )
}

export default DIT
