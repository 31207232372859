import errorRed from '@/assets/img/error-red.png'
import error from '@/assets/img/icons-error.png'
import ok from '@/assets/img/ok-green.png'
import { useAppSelector } from '@/hooks'
import { infoIconsSelector } from '@/modules/device-module/store/infoIconsSlice'
import { Status } from '@/modules/device-module/types/deviceType'
import { Box } from '@mui/material'

const containerWidth = { xss: 300, sm: 365, md: 365, lg: 365 }
const containerGap = { xss: 0.5, lg: 2 }

const selectImageSource = (status: Status) => {
  switch (status) {
    case Status.error:
      return errorRed
    case Status.warning:
      return error
    default:
      return ok
  }
}

const determineWarningMessage = (state?: Status, errorMessage?: string) => {
  if (state === Status.error) return errorMessage ?? 'Система неисправна'
  if (state === Status.warning) return errorMessage ?? 'Ошибки в работе системы'
  return ''
}

const WarningMessage = () => {
  const { state, errorMessage } = useAppSelector(infoIconsSelector)

  if (state === Status.ok) return null

  return (
    <Box
      display='flex'
      alignItems='center'
      width={containerWidth}
      gap={containerGap}
      className={'device-module-info-icons'}
    >
      <img
        // @ts-ignore
        src={selectImageSource(state)}
        alt='Ошибка'
        width={30}
        height={30}
      />
      <span style={{ color: '#fff' }}>
        {determineWarningMessage(state, errorMessage)}
      </span>
    </Box>
  )
}

export default WarningMessage
