import React, { useEffect } from 'react'
import DIT from '@/components/Organisms/DIT'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
import { useActions, useAppSelector } from '@/hooks'
import ErrorBoundary from '@/components/Organisms/ErrorBoundary'
import { useGetDitsQuery } from '@/components/Organisms/DIT/service'
import { useParams } from 'react-router-dom'
import { infoIconsSelector } from '@/modules/device-module/store/infoIconsSlice'
import { useGetUserProfileQuery } from '@/modules/user/services/userService'
import { UserTypes } from '@/types/typeUser'

const DITPage = () => {
  const { id } = useParams()
  const { data: currentUserData } = useGetUserProfileQuery('0')
  const { refetch } = useGetDitsQuery(Number(id))
  const programList = useAppSelector(ditSliceSelectors.stateDitPrograms)
  const { ditData } = useAppSelector(infoIconsSelector)
  const { ditSetDitSize, ditSetDisabledPrograms, ditReset } = useActions()
  useEffect(() => {
    ditReset()
    refetch()
  }, [])
  useEffect(() => {
    const activeProgram = programList.find((program) => program.index === 1000)
    if (activeProgram) {
      ditSetDisabledPrograms([activeProgram.id])
    }
    if (currentUserData?.data?.typeId === UserTypes.user) {
      const disabledProgramIds = programList.map((program) => program.id)
      ditSetDisabledPrograms(disabledProgramIds)
    }
  }, [programList])

  useEffect(() => {
    if (ditData?.width && ditData?.height) {
      ditSetDitSize({ width: ditData?.width, height: ditData?.height })
    }
  }, [ditData])

  return (
    <ErrorBoundary>
      <DIT
        data={programList}
        disableButtons={currentUserData?.data?.typeId === UserTypes.user}
      />
    </ErrorBoundary>
  )
}

export default DITPage
