import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { SlideConstructor } from '@/components/Organisms/DIT/types'
import type { Program, Slide } from '@/components/Organisms/DIT/types'
import { RootState } from '@/store'

type InitialStateType = {
  pageData: {
    width: number
    height: number
  }
  programList: Program[]
  disabledPrograms: number[]
  eventsList: any[]
  description: any[]
  settings: any[]
  activeProgram: number | null
  activeProgramDisabled: boolean
  activeSlide: number | null
  activeSlideItem: number | null
  hasChanges: boolean
  treeHasChanges: boolean
  deletedPrograms: number[]
}
const initialState: InitialStateType = {
  pageData: {
    width: 448,
    height: 112,
  },
  // programList: DATA,
  programList: [],
  deletedPrograms: [],
  disabledPrograms: [1000],
  activeProgramDisabled: false,
  eventsList: [],
  description: [],
  settings: [],
  activeProgram: null,
  activeSlide: null,
  activeSlideItem: null,
  hasChanges: false,
  treeHasChanges: false,
}
export const ditSlice = createSlice({
  name: 'ditSlice',
  initialState,
  reducers: {
    setDitDisabledPrograms: (state, action: PayloadAction<number[]>) => {
      state.disabledPrograms = action.payload
    },
    setDitSize: (
      state,
      action: PayloadAction<{ width: number; height: number }>
    ) => {
      const { width, height } = action.payload
      state.pageData = { width, height }
    },
    setUnsavedTreeChanges: (state, action: PayloadAction<boolean>) => {
      state.treeHasChanges = action.payload
    },
    setUnsavedChanges: (state, action: PayloadAction<boolean>) => {
      state.hasChanges = action.payload
    },
    setActiveProgram: (state, action: PayloadAction<number | null>) => {
      if (action.payload) {
        state.activeProgramDisabled = state.disabledPrograms.includes(
          action.payload
        )
      }
      state.activeSlide = null
      state.activeSlideItem = null
      state.activeProgram = action.payload
    },
    setProgramName: (
      state,
      action: PayloadAction<{ name: string; id: number }>
    ) => {
      const updatedList = state.programList.map((program) => {
        if (program.id === action.payload.id) {
          program.name = action.payload.name
          program.hasChanges = true
        }
        return program
      })
      state.programList = updatedList
    },
    setActiveSlide: (
      state,
      action: PayloadAction<{ programId: number; slideId: number }>
    ) => {
      const { programId, slideId } = action.payload
      state.activeProgramDisabled = state.disabledPrograms.includes(programId)
      state.activeProgram = programId
      state.activeSlide = slideId
      state.activeSlideItem = null
    },
    setSlideName: (
      state,
      action: PayloadAction<{
        name: string
        programId: number
        slideId: number
      }>
    ) => {
      const { name, programId, slideId } = action.payload
      const updatedList = [...state.programList]
      const program = updatedList.find((program) => program.id === programId)
      if (program) {
        program.hasChanges = true
        const slide = program?.slides.find((slide) => slide.id === slideId)
        if (slide) {
          slide.name = name
          state.programList = updatedList
        }
      }
    },
    setActiveSlideItem: (
      state,
      action: PayloadAction<{
        slideId: number
        programId: number
        slideItemId: number
      }>
    ) => {
      const { programId, slideId, slideItemId } = action.payload
      state.activeProgramDisabled = state.disabledPrograms.includes(programId)
      state.activeProgram = programId
      state.activeSlide = slideId
      state.activeSlideItem = slideItemId
    },
    resetPrograms: (state) => {
      state.hasChanges = false
      state.treeHasChanges = false
      state.deletedPrograms = []
      // state.programList = []
      state.activeProgram = null
      state.activeSlide = null
      state.activeSlideItem = null
    },
    setProgramList: (state, action: PayloadAction<Program[]>) => {
      if (action.payload && action.payload.length) {
        if (!state.activeProgram) {
          let activeIndex = action.payload.findIndex(
            (program) => program.active
          )
          if (activeIndex === -1) {
            activeIndex = 0
          }
          state.activeProgram = action.payload[activeIndex].id
          state.activeProgramDisabled = state.disabledPrograms.includes(
            action.payload[activeIndex].index
          )
        }
      }
      state.hasChanges = false
      state.programList = action.payload
    },
    addProgram: (state) => {
      const sortedListWithoutActive = state.programList
        .filter((program) => program.index !== 1000)
        .sort((a, b) => a.index - b.index)
      const lastIndex = sortedListWithoutActive.at(
        sortedListWithoutActive.length - 1
      )?.index
      const newId = lastIndex ? lastIndex + 1 : 1

      const newProgram = {
        id: state.programList.length + 1,
        name: `Программа ${newId}`,
        isNew: true,
        index: newId,
        slides: [],
      }
      state.activeProgram = state.programList.length + 1
      state.activeProgramDisabled = false
      state.activeSlide = null
      state.activeSlideItem = null
      state.programList = [...state.programList, newProgram]
    },
    removeProgram: (
      state,
      action: PayloadAction<{ pIndex: number; pId?: number }>
    ) => {
      const { pIndex, pId } = action.payload
      const filteredProgramList = state.programList.filter(
        (program) => program.id !== pIndex
      )
      if (pId) {
        state.deletedPrograms = [...state.deletedPrograms, pId]
      }
      state.treeHasChanges = true
      state.programList = [...filteredProgramList]
      state.activeProgram = null
    },
    resetDeletedPrograms: (state) => {
      state.deletedPrograms = []
    },
    addSlide: (state, action: PayloadAction<{ programId: number }>) => {
      const { programId } = action.payload
      const programIndex = state.programList.findIndex(
        (program) => program.id === programId
      )
      const newData = [...state.programList]
      const slideId = newData[programIndex].slides.length + 1
      newData[programIndex].slides.push({
        id: slideId,
        name: `Слайд ${newData[programIndex].slides.length + 1}`,
        index: newData[programIndex].slides.length + 1,
        items: [],
        flicker: false,
        showTime: 15,
        preview: '',
      })
      state.activeSlide = slideId
      state.programList = newData
    },
    changeSlide: (
      state,
      action: PayloadAction<{
        programId: number
        slideId: number
        showTime: number
        flicker: boolean
      }>
    ) => {
      const { programId, slideId, showTime, flicker } = action.payload
      const newData = [...state.programList]

      const programIndex = state.programList.findIndex(
        (program) => program.id === programId
      )
      const slideIndex = state.programList[programIndex].slides.findIndex(
        (slide) => slide.id === slideId
      )
      newData[programIndex].slides[slideIndex].flicker = flicker
      newData[programIndex].slides[slideIndex].showTime = showTime
      newData[programIndex].hasChanges = true
      state.hasChanges = true
      state.programList = newData
    },
    removeSlide: (
      state,
      action: PayloadAction<{ programId: number; slideId: number }>
    ) => {
      const { programId, slideId } = action.payload
      const newData = [...state.programList]
      const programIndex = state.programList.findIndex(
        (program) => program.id === programId
      )
      const filteredSlides = state.programList[programIndex].slides.filter(
        (slide) => slide.id !== slideId
      )
      newData[programIndex].slides = filteredSlides
      state.activeSlide = null
      state.treeHasChanges = true
      state.programList = newData
    },
    addSlideItem: (
      state,
      action: PayloadAction<{
        programId: number
        slideId: number
        type: 'text' | 'image'
      }>
    ) => {
      const { programId, slideId, type } = action.payload

      const programIndex = state.programList.findIndex(
        (program) => program.id === programId
      )
      const slideIndex = state.programList[programIndex].slides.findIndex(
        (program) => program.id === slideId
      )
      const newData = [...state.programList]
      const { slides } = newData[programIndex]
      const newId = slides[slideIndex].items.length + 1
      if (type === 'text') {
        slides[slideIndex].items.push({
          id: newId,
          isNew: true,
          i: String(newId),
          index: newId,
          w: state.pageData.width / 2,
          h: state.pageData.height / 2,
          width: state.pageData.width / 2,
          height: state.pageData.height / 2,
          x: 0,
          y: 0,
          type: type,
          color: '#ff0000',
          text: 'Текст',
          textAlign: 'left',
          fontWeight: 'normal',
          fontStyle: 'normal',
          textDecoration: 'none',
          fontSize: 12,
          name: 'Текст',
          parameters: [],
        })
      } else {
        slides[slideIndex].items.push({
          id: newId,
          isNew: true,
          i: String(newId),
          index: newId,
          width: state.pageData.width,
          height: state.pageData.height,
          w: state.pageData.width,
          h: state.pageData.height,
          x: 0,
          y: 0,
          type: type,
          imageUrl: null,
          imageId: String(newId),
          name: 'Изображение',
        })
      }
      newData[programIndex].hasChanges = true
      state.hasChanges = true
      state.activeSlideItem = newId
      state.programList = newData
    },
    renameSlideItem: (
      state,
      action: PayloadAction<{
        name: string
        programId: number
        slideId: number
        itemId: number
      }>
    ) => {
      const { name, programId, slideId, itemId } = action.payload
      const updatedList = [...state.programList]
      const program = updatedList.find((program) => program.id === programId)
      const slide = program?.slides.find((slide) => slide.id === slideId)
      if (program && slide) {
        program.hasChanges = true
        const item = slide.items.find((item) => item.id === itemId)
        if (item) {
          item.name = name
          state.programList = updatedList
        }
      }
    },
    removeSlideItem: (
      state,
      action: PayloadAction<{
        programId: number
        slideId: number
        slideItemId: number
      }>
    ) => {
      const { programId, slideId, slideItemId } = action.payload

      const programIndex = state.programList.findIndex(
        (program) => program.id === programId
      )
      const slideIndex = state.programList[programIndex].slides.findIndex(
        (program) => program.id === slideId
      )
      const filteredItems = state.programList[programIndex].slides[
        slideIndex
      ].items.filter((item) => item.id !== slideItemId)

      const newData = [...state.programList]
      newData[programIndex].slides[slideIndex].items = filteredItems
      newData[programIndex].hasChanges = true
      state.programList = newData
      state.treeHasChanges = true
      state.activeSlideItem = null
    },
    replaceSlideItem: (
      state,
      action: PayloadAction<{
        items: SlideConstructor[]
        programId: number
        slideId: number
      }>
    ) => {
      const { programId, slideId, items } = action.payload
      const programIndex = state.programList.findIndex(
        (program) => program.id === programId
      )
      const slideIndex = state.programList[programIndex].slides.findIndex(
        (program) => program.id === slideId
      )
      const newData = [...state.programList]
      newData[programIndex].hasChanges = true
      newData[programIndex].slides[slideIndex].items = items
      state.programList = newData
    },
    replaceSlideItems: (
      state,
      action: PayloadAction<{
        items: Slide[]
        programId: number
      }>
    ) => {
      const { programId, items } = action.payload
      const programIndex = state.programList.findIndex(
        (program) => program.id === programId
      )
      const newData = [...state.programList]
      newData[programIndex].slides = items
      newData[programIndex].hasChanges = true
      state.programList = newData
    },
  },
})

// selectors
const stateDitSlice = (state: RootState) => state.ditSliceReducer
const stateDitActiveProgram = (state: RootState) =>
  state.ditSliceReducer.activeProgram
const stateDitActiveProgramDisabled = (state: RootState) =>
  state.ditSliceReducer.activeProgramDisabled
const stateDitActiveSlide = (state: RootState) =>
  state.ditSliceReducer.activeSlide
const stateDitActiveSlideItem = (state: RootState) =>
  state.ditSliceReducer.activeSlideItem
const stateDitPrograms = (state: RootState) => state.ditSliceReducer.programList
const stateHasChanges = (state: RootState) => state.ditSliceReducer.hasChanges
const stateTreeHasChanges = (state: RootState) =>
  state.ditSliceReducer.treeHasChanges
const stateDeletedPrograms = (state: RootState) =>
  state.ditSliceReducer.deletedPrograms
const stateDisabledPrograms = (state: RootState) =>
  state.ditSliceReducer.disabledPrograms

export const ditSliceSelectors = {
  stateDitSlice,
  stateDitActiveProgram,
  stateDitActiveSlide,
  stateDitActiveSlideItem,
  stateDitPrograms,
  stateHasChanges,
  stateDitActiveProgramDisabled,
}
export const ditSliceReducer = ditSlice.reducer
export const ditSliceActions = ditSlice.actions
// export default ditSlice.reducer
