import { api } from '@/api'
import type { Program } from '@/components/Organisms/DIT/types'
import { ditSliceActions } from '@/components/Organisms/DIT/store'
import {
  removeIdForNewPrograms,
  cleanupItems,
  addConstructParams,
  filterOnlyChangedPrograms,
} from '@/components/Organisms/DIT/helpers'

type ResP = {
  deviceId: number
  programs: Program[]
  activeProgramId: number
}
type Req = {
  deviceId: number
  programs: Program[]
  deletedPrograms?: number[]
  activeProgramIndex: number
}
export type ResPVars = {
  parameters: {
    deviceId: number
    name: string
    parameters: { id: number; name: string; label: string }[]
  }[]
}

type ImageUploadResponce = {
  imageId: number
  imageUrl: string
  success: boolean
}

const dit = api.injectEndpoints({
  endpoints: (build) => ({
    GetDits: build.query<ResP, number>({
      query: (deviceId) => ({
        url: '/dit/programs',
        method: 'GET',
        params: { deviceId, isPreview: false },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const sortedByIndex = [...data.programs].sort(
            (a, b) => a.index - b.index
          )
          // dispatch(ditSliceActions.resetPrograms())
          dispatch(ditSliceActions.setProgramList(sortedByIndex))
        } catch (error) {
          console.log(error)
        }
      },
    }),
    GetDitsVariables: build.query<ResPVars, number>({
      query: (deviceId) => ({
        url: '/dit/parameters',
        method: 'GET',
        params: { deviceId },
      }),
    }),
    UploadDitImage: build.mutation<ImageUploadResponce, FormData>({
      query: (body) => ({
        url: '/dit/image',
        method: 'POST',
        body,
      }),
    }),
    UpdateDits: build.mutation<ResP, Req>({
      query: (body) => {
        let programs = filterOnlyChangedPrograms(body.programs)
        programs = removeIdForNewPrograms(programs)
        programs = cleanupItems(programs)
        body.programs = programs
        return {
          url: '/dit/programs',
          method: 'POST',
          body: body,
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // @ts-expect-error: Let's ignore TODO
          const res = data?.data as ResP
          const sortedByIndex = [...res.programs].sort(
            (a, b) => a.index - b.index
          )
          // const fullList = addConstructParams(sortedByIndex)
          dispatch(ditSliceActions.resetDeletedPrograms())
          // dispatch(ditSliceActions.setProgramList(fullList))
          dispatch(ditSliceActions.setProgramList(sortedByIndex))
          dispatch(ditSliceActions.setActiveProgram(res.activeProgramId))
        } catch (error) {
          console.log(error)
        }
      },
    }),
    SetActiveDit: build.mutation<ResP, { deviceId: number; programId: number }>(
      {
        query: (body) => ({
          url: '/dit/sendProgram',
          method: 'POST',
          body: body,
        }),
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled
            dispatch(ditSliceActions.setProgramList(data?.programs))
          } catch (error) {
            console.log(error)
          }
        },
      }
    ),
  }),
  // overrideExisting: false,
})

export const {
  useGetDitsQuery,
  useUploadDitImageMutation,
  useUpdateDitsMutation,
  useGetDitsVariablesQuery,
  useSetActiveDitMutation,
} = dit
