import React from 'react'
import dayjs, { Dayjs } from 'dayjs'
import TextField from '@mui/material/TextField'
import { TimePicker as TimePickerMui } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

type Props = {
  onChange: (start: string, end: string) => void
  brightnessEndTime: string
  brightnessStartTime: string
}

const DATE_PREFIX = '2014-08-18T'
const TIME_FORMAT = 'HH:mm:ss'

const TimePicker: React.FC<Props> = ({
  onChange,
  brightnessEndTime,
  brightnessStartTime,
}) => {
  const startTime = dayjs(`${DATE_PREFIX}${brightnessStartTime}`)
  const endTime = dayjs(`${DATE_PREFIX}${brightnessEndTime}`)
  const [start, setStart] = React.useState<Dayjs | null>(startTime)
  const [end, setEnd] = React.useState<Dayjs | null>(endTime)

  React.useEffect(() => {
    const formattedStartTime = dayjs(start).format(TIME_FORMAT)
    const formattedEndTime = dayjs(end).format(TIME_FORMAT)
    if (
      formattedStartTime !== dayjs(startTime).format(TIME_FORMAT) ||
      formattedEndTime !== dayjs(endTime).format(TIME_FORMAT)
    ) {
      console.log('TimePicker changed')
      onChange(formattedStartTime, formattedEndTime)
    }
  }, [start, end])

  const handleChangeStart = (newValue: Dayjs | null) => {
    setStart(newValue)
  }
  const handleChangeEnd = (newValue: Dayjs | null) => {
    setEnd(newValue)
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale='ru'
      >
        <TimePickerMui
          value={start}
          onChange={handleChangeStart}
          renderInput={(params) => (
            <TextField
              style={{ background: '#fff', width: '120px' }}
              {...params}
            />
          )}
        />
        <TimePickerMui
          value={end}
          onChange={handleChangeEnd}
          renderInput={(params) => (
            <TextField
              style={{ background: '#fff', width: '120px' }}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  )
}

export default TimePicker
