import FeaturesDitForm from './FeaturesDitForm'
import { useGetNewUnitQuery } from '@/modules/add-new-unit/services/newUnitService'
import { UnitSearchReq } from '@/modules/devices-list/types/newUnitsType'
import { useGetUnitsHomeMapMutation } from '@/modules/maps/services/homeMapService'
import { useGetUserProfileQuery } from '@/modules/user/services/userService'
import { UserTypes } from '@/types/typeUser'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const FeaturesDit = () => {
  const { id } = useParams()
  const { data: currentUserData } = useGetUserProfileQuery('0')
  const currentUserType = currentUserData?.data.typeId

  const { data: properties, refetch } = useGetNewUnitQuery(
    { id: Number(id) },
    {
      skip: currentUserType !== UserTypes.superAdmin,
    }
  )
  const [getDataUnits, { data: locations }] = useGetUnitsHomeMapMutation()

  const getLocations = async () => {
    try {
      const usersSearchData: Omit<UnitSearchReq, 'page'> = {
        value: '',
        deviceTypeFilter: [0],
        deviceStateFilter: [0],
        favoriteId: 0,
      }
      await getDataUnits(usersSearchData).unwrap()
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getLocations()
  }, [])

  return (
    <>
      {properties?.data && currentUserType && (
        <FeaturesDitForm
          refetch={refetch}
          formValues={properties.data}
          locations={locations}
          currentUserType={currentUserType}
        />
      )}
    </>
  )
}

export default FeaturesDit
