import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from '@/components/Organisms/DIT/DITTree/DITTree.module.scss'
import DialogChanges from '@/components/Dialog/DialogChanges'
import { IconButton } from '@mui/material'
import IconDelete from '@/components/Atoms/Icons/IconDelete'
import type { Program } from '@/components/Organisms/DIT/types'
import IconDown from '@/components/Atoms/Icons/IconDown'
import IconRight from '@/components/Atoms/Icons/IconRight'
import IconAddDoc from '@/components/Atoms/Icons/IconAddDoc'
import DITISlides from '@/components/Organisms/DIT//DITTree/DITSlides'
import { useActions, useAppSelector } from '@/hooks'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'

type Props = {
  editMode: boolean
  program: Program
}
const DITProgram: React.FC<Props> = ({ program, editMode }) => {
  const [modalRemove, setModalRemove] = React.useState(false)
  const activeProgram = useAppSelector(ditSliceSelectors.stateDitActiveProgram)
  const [programCollapsed, setProgramCollapsed] = React.useState(false)
  const { ditSetActiveProgram, ditRemoveProgram, ditUpdateProgramName } =
    useActions()
  const toggleProgramCollapsed = () => {
    setProgramCollapsed(!programCollapsed)
  }
  const handleActiveProgram = () => {
    ditSetActiveProgram(program.id)
  }
  const removeHandler = () => {
    setModalRemove(true)
  }
  const approvedRemoveHandler = () => {
    ditRemoveProgram({ pIndex: program.id, pId: program.id })
    setModalRemove(false)
  }
  const handleChangeName = (e: any) => {
    ditUpdateProgramName({ name: e.target.value, id: program.id })
  }
  const isActive = program.id === activeProgram
  return (
    <div className={styles.ProgramWrapper}>
      <DialogChanges
        open={modalRemove}
        handleAgree={approvedRemoveHandler}
        handleClose={() => setModalRemove(false)}
        handleDisAgree={() => setModalRemove(false)}
        title={'Удалить элемент?'}
        btnSaveTitle={'Удалить'}
        message={
          'ВНИМАНИЕ! Если элемент содержит вложения - они будут также удалены ?'
        }
      />
      <div
        className={cn(styles.ProgramRow, {
          [styles.ProgramTitleActive]: isActive,
        })}
      >
        <div className={styles.ProgramRowLeft}>
          <span
            style={{
              visibility: program.slides.length ? 'visible' : 'hidden',
            }}
            onClick={toggleProgramCollapsed}
            className={styles.ToggleArrow}
          >
            {programCollapsed ? <IconRight /> : <IconDown />}
          </span>
          {program.active ? (
            <PlayCircleIcon
              htmlColor='rgba(0, 0, 0, 0.54)'
              fontSize='medium'
            />
          ) : (
            <IconAddDoc />
          )}
          {!program.active && editMode ? (
            <>
              <input
                defaultValue={program.name}
                onChange={handleChangeName}
                className={styles.EditableNameInput}
                type='text'
              />
            </>
          ) : (
            <span onClick={handleActiveProgram}>{program.name}</span>
          )}
        </div>
        {!program.active && editMode && (
          <IconButton onClick={removeHandler}>
            <IconDelete />
          </IconButton>
        )}
      </div>
      {!programCollapsed && (
        <DITISlides
          program={program}
          slides={program.slides}
          editMode={editMode}
        />
      )}
      {/*{!programCollapsed &&*/}
      {/*  program.slides.map((slide) => (*/}
      {/*    <DITISlides*/}
      {/*      program={program}*/}
      {/*      programId={program.index}*/}
      {/*      key={slide.index}*/}
      {/*      slide={slide}*/}
      {/*      editMode={editMode}*/}
      {/*    />*/}
      {/*  ))}*/}
    </div>
  )
}

export default DITProgram
