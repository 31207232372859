import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material'
import React, { FC } from 'react'
import CloseIcon from '@mui/icons-material/Close'

interface IDialog {
  open: boolean
  handleClose: () => void
  handleAgree: () => void
  handleDisAgree: () => void
  message?: string
  title?: string
  loading?: boolean
  btnSaveTitle?: string
  btnCancelTitle?: string
  showButtons?: boolean
}

const DialogChanges: FC<IDialog> = ({
  open,
  handleClose,
  message,
  handleAgree,
  handleDisAgree,
  title,
  loading,
  btnSaveTitle = 'Сохранить',
  btnCancelTitle = 'Отменить',
  showButtons = true,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      {/*<IconButton*/}
      {/*  aria-label='close'*/}
      {/*  onClick={handleClose}*/}
      {/*  sx={(theme) => ({*/}
      {/*    position: 'absolute',*/}
      {/*    right: 8,*/}
      {/*    top: 8,*/}
      {/*    color: theme.palette.grey[500],*/}
      {/*  })}*/}
      {/*>*/}
      {/*  <CloseIcon />*/}
      {/*</IconButton>*/}
      {title ? <DialogTitle>{title}</DialogTitle> : null}
      {message ? (
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
      ) : null}
      {showButtons && (
        <DialogActions>
          <Button
            onClick={handleDisAgree}
            disabled={loading}
          >
            {btnCancelTitle}
          </Button>
          <LoadingButton
            onClick={handleAgree}
            autoFocus
            loading={loading}
            variant={'contained'}
          >
            {btnSaveTitle}
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default DialogChanges
