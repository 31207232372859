import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  MenuItem,
  Select,
  InputLabel,
  Box,
} from '@mui/material'
import { ResPVars } from '../service'
import type { ConstructorStyleItem } from '../types'
import { LoadingButton } from '@mui/lab'
import {
  useUpdateDitsMutation,
  useGetDitsVariablesQuery,
} from '@/components/Organisms/DIT/service'
import { useAppSelector } from '@/hooks'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
import { useParams } from 'react-router-dom'

type Variable = ConstructorStyleItem['parameters'][0]
const INTERVALS = [15, 30, 45, 60]
const ModalVariable: React.FC<{
  showModal: boolean
  setShowModal: (state: boolean) => void
  setVariables: (variable: Variable) => void
}> = ({ showModal, setShowModal, setVariables }) => {
  const { id } = useParams()
  const { data, isLoading } = useGetDitsVariablesQuery(Number(id))
  const [selectedSource, setSelectedSource] = React.useState<
    ResPVars['parameters'][0] | undefined
  >(undefined)
  const [selectedValue, setSelectedValue] = React.useState('')
  const [intervalValue, setIntervalValue] = React.useState(INTERVALS[0])
  // const programsState = useAppSelector(ditSliceSelectors.stateDitSlice)
  // const [saveDIts] = useUpdateDitsMutation()
  React.useEffect(() => {
    const sourceValue = data?.parameters[0]
    if (sourceValue) {
      setSelectedSource(sourceValue)
      setSelectedValue(sourceValue.parameters[0].name)
    }
  }, [data])

  const handleSave = async () => {
    if (selectedSource) {
      const sourceParam = selectedSource.parameters.find(
        (item) => item.name === selectedValue
      )
      if (sourceParam) {
        const newVar: Variable = {
          deviceId: selectedSource.deviceId,
          typeId: sourceParam.id,
          label: sourceParam.label,
          interval: intervalValue,
        }
        setVariables(newVar)
        setShowModal(false)
      }
    }
  }
  const handleClose = () => {
    setShowModal(false)
  }
  const handleChangeSource = (e: any) => {
    const sourceValue = data?.parameters.find(
      (item) => item.name === e.target.value
    )
    if (sourceValue) {
      setSelectedSource(sourceValue)
      setSelectedValue(sourceValue.parameters[0].name)
    }
  }
  const handleChangeValue = (e: any) => {
    setSelectedValue(e.target.value)
  }
  const handleInterval = (e: any) => {
    setIntervalValue(e.target.value)
  }
  return (
    <Dialog open={showModal}>
      <DialogTitle>Выбор источника данных</DialogTitle>
      <DialogContent>
        <Box
          display={'flex'}
          gap={3}
          pb={2}
          alignItems={'center'}
        >
          <InputLabel style={{ width: 100 }}>Тип системы</InputLabel>
          <Select
            color='primary'
            size='small'
            disabled
            value={'Метеостанция'}
            onChange={handleChangeSource}
          >
            <MenuItem value={'Метеостанция'}>Метеостанция</MenuItem>
          </Select>
        </Box>
        {isLoading ? (
          <p>Loading</p>
        ) : (
          <div>
            {data && selectedSource && (
              <>
                <Box
                  display={'flex'}
                  gap={3}
                  pb={2}
                  alignItems={'center'}
                >
                  <InputLabel style={{ width: 100 }}>Источник</InputLabel>
                  <Select
                    color='primary'
                    size='small'
                    value={selectedSource.name}
                    onChange={handleChangeSource}
                  >
                    {data.parameters.map((item) => (
                      <MenuItem
                        key={item.name}
                        value={item.name}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box
                  display={'flex'}
                  gap={3}
                  pb={2}
                  alignItems={'center'}
                >
                  <InputLabel style={{ width: 100 }}>Параметр</InputLabel>
                  <Select
                    color='primary'
                    size='small'
                    value={selectedValue}
                    onChange={handleChangeValue}
                  >
                    {selectedSource.parameters.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.name}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </>
            )}
            <Box
              display={'flex'}
              gap={3}
              alignItems={'center'}
            >
              <InputLabel style={{ width: 100 }}>Интервал</InputLabel>
              <Select
                color='primary'
                size='small'
                value={intervalValue}
                onChange={handleInterval}
              >
                {INTERVALS.map((item) => (
                  <MenuItem
                    key={item}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant='outlined'
        >
          Нет
        </Button>
        <LoadingButton
          onClick={handleSave}
          variant='contained'
        >
          Да
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default ModalVariable
