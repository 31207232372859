import greenLocation from '@/assets/img/location-green.png'
import redLocation from '@/assets/img/location-red.png'
import yellowLocation from '@/assets/img/location-yellow.png'
import { useActions } from '@/hooks'
import { UnitsMapTypeRes } from '@/modules/maps-tank/types/unitsMapType'
import cn from '@/utils/cn'
import { Box } from '@mui/material'
import {
  Map,
  Placemark,
  SearchControl,
  TypeSelector,
  ZoomControl,
} from '@pbe/react-yandex-maps'
import React, { FC, memo, useRef } from 'react'

const s = cn('device-module-feature')

interface Props {
  center: number[]
  setNewCoords: any
  // setNewCoords: React.Dispatch<React.SetStateAction<number[]>>
  dataUnits: UnitsMapTypeRes | undefined
  newCoords: number[]
  draggable: boolean
}

const MapFeatures: FC<Props> = ({
  center,
  setNewCoords,
  dataUnits,
  newCoords,
  draggable,
}) => {
  const { setIsChangeFeatures } = useActions()

  const refMark = useRef<any>(null)

  return (
    <Map
      state={{
        center: center,
        zoom: 9,
        controls: [],
      }}
      options={{
        yandexMapDisablePoiInteractivity: true,
      }}
      modules={['control.ZoomControl']}
      width='100%'
      height='100%'
      onClick={(event: any) => {
        const coords = event.get('coords')
        setNewCoords(() => coords)
        setIsChangeFeatures(true)
      }}
    >
      <SearchControl options={{ float: 'left', noPlacemark: true }} />
      <ZoomControl
        options={{
          position: {
            right: 10,
            left: 'auto',
            top: (document.documentElement.scrollHeight - 530) / 2,
            bottom: 'auto',
          },
          // @ts-ignore
          float: 'right',
        }}
      />
      <TypeSelector
        options={{
          // @ts-ignore
          panoramasItemMode: 'off',
        }}
      />
      {dataUnits?.data?.data?.map((unit, i) => {
        return (
          <Placemark
            key={unit.id}
            defaultGeometry={unit.coordinates}
            options={{
              iconLayout: 'default#image',
              iconImageSize: [30, 30],
              iconImageHref:
                unit.state === 1
                  ? greenLocation
                  : unit.state === 2
                    ? yellowLocation
                    : redLocation,
            }}
          />
        )
      })}
      <Placemark
        instanceRef={refMark}
        onDragEnd={() => {
          const coords = refMark.current.geometry._coordinates
          setNewCoords(() => coords)
          setIsChangeFeatures(true)
        }}
        geometry={newCoords}
        options={{
          iconImageSize: [50, 60],
          iconLayout: 'default#image',
          draggable: !draggable,
          preset: 'islands#greenIcon',
        }}
        properties={{
          iconContent: '+',
        }}
      />
    </Map>
  )
}

export default memo(MapFeatures)
