import car from '@/assets/img/car.png'
import redCar from '@/assets/img/car_red.png'
import cloudBlue from '@/assets/img/cloud-blue.png'
import cloudGray from '@/assets/img/cloud-gray.png'
import cloudRed from '@/assets/img/cloud-red.png'
import electrical from '@/assets/img/electricalBlue.png'
import errorRed from '@/assets/img/error-red.png'
import battary100 from '@/assets/img/full-battery.png'
import signal100 from '@/assets/img/full-connection.png'
import error from '@/assets/img/icons-error.png'
import photo from '@/assets/img/icons8-camera-80 (1).png'
import video from '@/assets/img/icons8-camera-80.png'
import battary10 from '@/assets/img/low-battery.png'
import battary50 from '@/assets/img/medium-battery.png'
import signal50 from '@/assets/img/medium-connection.png'
import signal0 from '@/assets/img/no-connection.png'
import ok from '@/assets/img/ok-green.png'
import proc from '@/assets/img/processor.png'
import { Status } from '@/modules/device-module/types/deviceType'
import { signal, voltage12, voltage24, VoltageEnum } from '@/utils'
import cn from '@/utils/cn'
import {
  getMeteoImage,
  TypeSynop,
  TypeUltraShortTermForecastId,
} from '@/utils/meteo'
import { Pagination, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import dayjs from 'dayjs'
import * as React from 'react'
import { FC } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Unit } from '../../types/newUnitsType'

const s = cn('devices-list')

interface Props {
  units: Unit[]
  count: number
  pages: number
  page: number
  handleChangePage: (event: unknown, newPage: number) => void
}

const TableView: FC<Props> = ({
  units,
  page,
  pages,
  handleChangePage,
  count,
}) => {
  const navigate = useNavigate()
  return (
    <>
      <TableContainer
        sx={{
          height: '95%',
          overflowY: 'auto',
          backgroundColor: '#fff',
        }}
        className={s('tableContainer')}
      >
        <Table size='small'>
          <TableBody>
            {units.length === 0 ? (
              <TableRow>
                <TableCell
                  component='th'
                  scope='row'
                >
                  <Typography textAlign='center'>
                    Ни одной записи не найдено
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {units.map((unit, index) => (
                  <TableRow
                    key={index}
                    hover
                    tabIndex={-1}
                    className={s()}
                  >
                    <TableCell
                      component='th'
                      scope='row'
                    >
                      <Box
                        display='flex'
                        alignItems='end'
                        gap={1}
                      >
                        <Link
                          to={`/${unit?.typeId === 7 ? 'photos' : 'devices'}/${unit.id}`}
                          className={s('link')}
                        >
                          {unit.typeId === 8 || unit.typeId === 7 ? (
                            <span
                              className={s('name')}
                              style={{ color: 'gray' }}
                            >
                              {unit.type + ' ' + unit.name}
                            </span>
                          ) : (
                            <span
                              className={s('name')}
                              style={{ color: 'gray' }}
                            >
                              {unit.name}
                            </span>
                          )}
                          <span
                            className={s('address')}
                            style={{ color: '#0072BC' }}
                          >
                            {unit.address}
                          </span>
                        </Link>
                      </Box>
                    </TableCell>
                    <TableCell
                      align='right'
                      onClick={() =>
                        navigate(
                          `/${unit?.typeId === 7 ? 'photos' : 'devices'}/${unit.id}`
                        )
                      }
                      sx={{
                        color: '#0072BC',
                        cursor: 'pointer',
                      }}
                    >
                      {unit.smart || unit.typeId === 9 ? (
                        <Box
                          display='flex'
                          justifyContent='flex-end'
                          gap={2}
                          flexWrap={{ xss: 'wrap', md: 'nowrap' }}
                          flexDirection={{ xss: 'column', md: 'row' }}
                        >
                          {unit.state && unit.state > 1 ? (
                            <Tooltip
                              title={unit.errorMessage}
                              placement='top'
                            >
                              <img
                                src={
                                  unit?.state === Status.error
                                    ? errorRed
                                    : unit?.state === Status.warning
                                      ? error
                                      : ok
                                }
                                alt='Ошибка'
                                width={25}
                                height={25}
                              />
                            </Tooltip>
                          ) : null}
                          {unit?.ultraShortTermForecastId > 1 ? (
                            <Box
                              display='flex'
                              alignItems='center'
                              gap={1}
                              width={30}
                            >
                              <Tooltip
                                title={
                                  TypeUltraShortTermForecastId[
                                    unit?.ultraShortTermForecastId as keyof typeof TypeUltraShortTermForecastId
                                  ]
                                }
                                placement='top'
                              >
                                <img
                                  width={25}
                                  height={25}
                                  src={
                                    unit?.ultraShortTermForecastId === 2 ||
                                    unit?.ultraShortTermForecastId === 4
                                      ? car
                                      : redCar
                                  }
                                  alt='Процессор'
                                />
                              </Tooltip>
                            </Box>
                          ) : null}
                          {unit?.typeId === 4 &&
                          unit?.currentPrecipitationId > 1 ? (
                            <Box
                              display='flex'
                              alignItems='center'
                              gap={1}
                              width={30}
                            >
                              <Tooltip
                                title={
                                  TypeSynop[
                                    unit.precipitationId as keyof typeof TypeSynop
                                  ]
                                }
                                placement='top'
                              >
                                <img
                                  src={getMeteoImage(
                                    unit.currentPrecipitationId
                                  )}
                                  alt='Ошибка'
                                  width={25}
                                  height={25}
                                />
                              </Tooltip>
                            </Box>
                          ) : null}
                          {unit?.typeId === 4 ? (
                            <Box
                              display='flex'
                              alignItems='center'
                              gap={1}
                              width='fit-content'
                            >
                              <span>{unit.temperature + '°C'}</span>
                            </Box>
                          ) : null}
                          {unit.typeId !== 9 && (
                            <Box
                              display='flex'
                              alignItems='center'
                              gap={1}
                              width={60}
                            >
                              <Tooltip
                                title={'Количество модулей в системе'}
                                placement='top'
                              >
                                <img
                                  width={25}
                                  height={25}
                                  src={proc}
                                  alt='Процессор'
                                />
                              </Tooltip>
                              <span>{unit.countModules}</span>
                            </Box>
                          )}
                          <Box
                            display='flex'
                            alignItems='center'
                            gap={1}
                            width={
                              units.filter(
                                (unit) => unit.connectionStatus === 3
                              ).length === 0
                                ? 97
                                : 150
                            }
                          >
                            {unit.connectionStatus === 1 ? (
                              <>
                                <Tooltip
                                  title={'Состояние подключения'}
                                  placement='top'
                                >
                                  <img
                                    width={25}
                                    height={25}
                                    src={cloudBlue}
                                    alt='В сети'
                                  />
                                </Tooltip>
                                <span>В сети</span>
                              </>
                            ) : unit.connectionStatus === 2 ? (
                              <>
                                <Tooltip
                                  title={'Состояние подключения'}
                                  placement='top'
                                >
                                  <img
                                    width={25}
                                    height={25}
                                    src={cloudGray}
                                    alt='Не в сети'
                                  />
                                </Tooltip>
                                <span>Не в сети</span>
                              </>
                            ) : unit.connectionStatus === 3 ? (
                              <>
                                <Tooltip
                                  title={'Состояние подключения'}
                                  placement='top'
                                >
                                  <img
                                    width={25}
                                    height={25}
                                    src={cloudRed}
                                    alt='Нет соединения'
                                  />
                                </Tooltip>
                                <span>
                                  {unit?.lastPackageDate
                                    ? dayjs(unit.lastPackageDate).format(
                                        'DD.MM.YYYY HH:mm'
                                      )
                                    : 'Нет соединения'}
                                </span>
                              </>
                            ) : null}
                          </Box>
                          {unit.typeId !== 9 && (
                            <Box
                              display='flex'
                              alignItems='center'
                              gap={1}
                              width={70}
                            >
                              {signal(unit.signal) === 'low' ? (
                                <>
                                  <Tooltip
                                    title={'Уровень GSM сигнала'}
                                    placement='top'
                                  >
                                    <img
                                      width={25}
                                      height={25}
                                      src={signal0}
                                      alt='Соединение'
                                    />
                                  </Tooltip>
                                  <span>{unit.signal}%</span>
                                </>
                              ) : signal(unit.signal) === 'medium' ? (
                                <>
                                  <Tooltip
                                    title={'Уровень GSM сигнала'}
                                    placement='top'
                                  >
                                    <img
                                      width={25}
                                      height={25}
                                      src={signal50}
                                      alt='Соединение'
                                    />
                                  </Tooltip>
                                  <span>{unit.signal}%</span>
                                </>
                              ) : signal(unit.signal) === 'high' ? (
                                <>
                                  <Tooltip
                                    title={'Уровень GSM сигнала'}
                                    placement='top'
                                  >
                                    <img
                                      width={25}
                                      height={25}
                                      src={signal100}
                                      alt='Соединение'
                                    />
                                  </Tooltip>
                                  <span>{unit.signal}%</span>
                                </>
                              ) : null}
                            </Box>
                          )}

                          {unit.typeId !== 9 && (
                            <Box
                              display='flex'
                              alignItems='center'
                              gap={1}
                              width={100}
                            >
                              {unit.is220v ? (
                                <>
                                  <Tooltip
                                    title={'Питание от сети'}
                                    placement='top'
                                  >
                                    <img
                                      width={25}
                                      height={25}
                                      src={electrical}
                                      alt='Вилка'
                                    />
                                  </Tooltip>
                                  <span>
                                    {Number(unit.voltage).toFixed(2)}В
                                  </span>
                                </>
                              ) : (
                                <>
                                  {unit.voltage <= VoltageEnum.min ? (
                                    <>
                                      {voltage12(unit.voltage) === 'low' ? (
                                        <>
                                          <Tooltip
                                            title={'Напряжение питания'}
                                            placement='top'
                                          >
                                            <img
                                              width={25}
                                              height={25}
                                              src={battary10}
                                              alt='Батарея'
                                            />
                                          </Tooltip>
                                          <span>
                                            {Number(unit.voltage).toFixed(2)}В
                                          </span>
                                        </>
                                      ) : voltage12(unit.voltage) ===
                                        'medium' ? (
                                        <>
                                          <Tooltip
                                            title={'Напряжение питания'}
                                            placement='top'
                                          >
                                            <img
                                              width={25}
                                              height={25}
                                              src={battary50}
                                              alt='Батарея'
                                            />
                                          </Tooltip>
                                          <span>
                                            {Number(unit.voltage).toFixed(2)}В
                                          </span>
                                        </>
                                      ) : voltage12(unit.voltage) === 'high' ? (
                                        <>
                                          <Tooltip
                                            title={'Напряжение питания'}
                                            placement='top'
                                          >
                                            <img
                                              width={25}
                                              height={25}
                                              src={battary100}
                                              alt='Батарея'
                                            />
                                          </Tooltip>
                                          <span>
                                            {Number(unit.voltage).toFixed(2)}В
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <Tooltip
                                            title={'Напряжение питания'}
                                            placement='top'
                                          >
                                            <img
                                              width={25}
                                              height={25}
                                              src={battary10}
                                              alt='Батарея'
                                            />
                                          </Tooltip>
                                          <span>
                                            {Number(unit.voltage).toFixed(2)}В
                                          </span>
                                        </>
                                      )}
                                    </>
                                  ) : unit.voltage > VoltageEnum.min &&
                                    unit.voltage <= VoltageEnum.max ? (
                                    <>
                                      {voltage24(unit.voltage) === 'low' ? (
                                        <>
                                          <Tooltip
                                            title={'Напряжение питания'}
                                            placement='top'
                                          >
                                            <img
                                              width={25}
                                              height={25}
                                              src={battary10}
                                              alt='Батарея'
                                            />
                                          </Tooltip>
                                          <span>
                                            {Number(unit.voltage).toFixed(2)}В
                                          </span>
                                        </>
                                      ) : voltage24(unit.voltage) ===
                                        'medium' ? (
                                        <>
                                          <Tooltip
                                            title={'Напряжение питания'}
                                            placement='top'
                                          >
                                            <img
                                              width={25}
                                              height={25}
                                              src={battary50}
                                              alt='Батарея'
                                            />
                                          </Tooltip>
                                          <span>
                                            {Number(unit.voltage).toFixed(2)}В
                                          </span>
                                        </>
                                      ) : voltage24(unit.voltage) === 'high' ? (
                                        <>
                                          <Tooltip
                                            title={'Напряжение питания'}
                                            placement='top'
                                          >
                                            <img
                                              width={25}
                                              height={25}
                                              src={battary100}
                                              alt='Батарея'
                                            />
                                          </Tooltip>
                                          <span>
                                            {Number(unit.voltage).toFixed(2)}В
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <Tooltip
                                            title={'Напряжение питания'}
                                            placement='top'
                                          >
                                            <img
                                              width={25}
                                              height={25}
                                              src={battary10}
                                              alt='Батарея'
                                            />
                                          </Tooltip>
                                          <span>
                                            {Number(unit.voltage).toFixed(2)}В
                                          </span>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <Tooltip
                                        title={'Напряжение питания'}
                                        placement='top'
                                      >
                                        <img
                                          width={25}
                                          height={25}
                                          src={battary10}
                                          alt='Батарея'
                                        />
                                      </Tooltip>
                                      <span>
                                        {Number(unit.voltage).toFixed(2)}В
                                      </span>
                                    </>
                                  )}
                                </>
                              )}
                            </Box>
                          )}
                        </Box>
                      ) : unit.typeId === 7 || unit.typeId === 8 ? (
                        <Box
                          display='flex'
                          justifyContent='flex-end'
                          pr={{ xss: 3, lg: 3, xlg: 3 }}
                          gap={2}
                          flexWrap={{ xss: 'wrap', md: 'nowrap' }}
                          flexDirection={{ xss: 'column', md: 'row' }}
                        >
                          <Box width={{ xss: 0, md: 60 }} />
                          <Box
                            display='flex'
                            alignItems='center'
                            gap={1}
                            width={
                              units.filter(
                                (unit) =>
                                  unit.connectionStatus === 3 ||
                                  unit.connectionStatus === 2
                              ).length === 0
                                ? 97
                                : 150
                            }
                          >
                            {unit.connectionStatus === 1 ? (
                              <>
                                <Tooltip
                                  title={'Состояние подключения'}
                                  placement='top'
                                >
                                  <img
                                    width={25}
                                    height={25}
                                    src={cloudBlue}
                                    alt='В сети'
                                  />
                                </Tooltip>
                                <span>В сети</span>
                              </>
                            ) : unit.connectionStatus === 2 ? (
                              <>
                                <Tooltip
                                  title={'Состояние подключения'}
                                  placement='top'
                                >
                                  <img
                                    width={25}
                                    height={25}
                                    src={cloudGray}
                                    alt='Не в сети'
                                  />
                                </Tooltip>
                                <span>Не в сети</span>
                              </>
                            ) : unit.connectionStatus === 3 ? (
                              <>
                                <Tooltip
                                  title={'Состояние подключения'}
                                  placement='top'
                                >
                                  <img
                                    width={25}
                                    height={25}
                                    src={cloudRed}
                                    alt='Нет соединения'
                                  />
                                </Tooltip>
                                <span>Нет соединения</span>
                              </>
                            ) : null}
                          </Box>
                          <Box
                            display='flex'
                            alignItems='center'
                            pl='2px'
                            gap={1}
                            width={160}
                          >
                            {unit.typeId === 7 ? (
                              <>
                                <Tooltip
                                  title={'Время прихода последнего изображения'}
                                  placement='top'
                                >
                                  <img
                                    width={25}
                                    height={25}
                                    src={unit.typeId === 7 ? photo : video}
                                    alt='camera'
                                  />
                                </Tooltip>
                                <span>
                                  {unit.lastPhotoDate
                                    ? dayjs(unit.lastPhotoDate).format(
                                        'DD.MM.YYYY HH:mm'
                                      )
                                    : '-'}
                                </span>
                              </>
                            ) : null}
                          </Box>
                        </Box>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={{ xss: 0, md: 2 }} />
      <Box
        display='flex'
        width='100%'
        justifyContent='flex-end'
      >
        {count <= 25 ? null : (
          <Pagination
            count={pages}
            page={page}
            onChange={handleChangePage}
            size='small'
            siblingCount={1}
            boundaryCount={2}
            color={'primary'}
          />
        )}
      </Box>
    </>
  )
}

export default TableView
