export enum DeviceType {
  smartTurn = 1,
  smartLight = 2,
  meteo = 4,
  ksodd = 5,
  kao = 6,
  photo = 7,
  video = 8,
  dit = 9,
  trafficLight = 11,
}

export const SmartDevicesIds = [
  DeviceType.smartTurn,
  DeviceType.smartLight,
  DeviceType.meteo,
]

export const SimpleDevicesIds = [
  DeviceType.kao,
  DeviceType.ksodd,
  DeviceType.trafficLight,
]
