import { ConstructorItemProps, Program, Slide, SlideConstructor } from './types'
export const getActiveProgram = (
  id: number | null,
  programList: Program[]
): Program | undefined => {
  return programList.find((item) => item.id === id)
}
export const getActiveSlide = (
  id: number | null,
  slidesList: Slide[] | undefined
): Slide => {
  // @ts-ignore: Unreachable code error
  return slidesList?.find((item) => item.id === id)
}
export const removeIdForNewPrograms = (programList: Program[]) => {
  return programList.map((program) => {
    const id = program.isNew ? 0 : program.id
    return { ...program, id }
  })
}
export const cleanupItems = (programList: Program[]) => {
  const programs = structuredClone(programList)
  if (programs.length) {
    programs.forEach((program) => {
      delete program.isNew
      delete program.hasChanges
      const { slides } = program
      if (slides.length) {
        slides.forEach((slide) => {
          const { items } = slide
          if (slide.preview) {
            // @ts-ignore
            delete slide.preview
          }
          if (items.length) {
            const newItems = items.map((item) => {
              const {
                id,
                name,
                type,
                index,
                x,
                y,
                w,
                h,
                isNew,
                width,
                height,
              } = item
              const baseItem = {
                id: isNew ? null : id,
                name,
                type,
                x,
                y,
                width: width || w,
                height: height || h,
                index,
              }
              if (item.type === 'text') {
                const {
                  text,
                  color,
                  textAlign,
                  fontWeight,
                  fontSize,
                  textDecoration,
                  fontStyle,
                  parameters,
                } = item
                Object.assign(baseItem, {
                  text,
                  color,
                  textAlign,
                  fontWeight,
                  fontSize,
                  textDecoration,
                  fontStyle,
                  parameters,
                })
              }
              if (item.type === 'image') {
                const { imageId } = item
                Object.assign(baseItem, { imageId })
              }
              return baseItem
            })
            slide.items = newItems as unknown as SlideConstructor[]
          }
        })
      }
    })
  }
  return programs
}

export const addConstructParams = (programList: Program[]) => {
  // const programs = structuredClone(programList)
  // if (programs.length) {
  //   programs.forEach((program) => {
  //     const { slides } = program
  //     if (slides.length) {
  //       slides.forEach((slide) => {
  //         const { items } = slide
  //         if (items.length) {
  //           const newItems = items.map((item) => {
  //             return { ...item, w: item.width, h: item.height }
  //           })
  //           slide.items = newItems as unknown as SlideConstructor[]
  //         }
  //       })
  //     }
  //   })
  // }
  // return programs
}

export const filterOnlyChangedPrograms = (programList: Program[]) => {
  return programList.filter((program) => program.hasChanges || program.isNew)
}
